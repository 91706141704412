//公告详情
<template>
  <div class="plate-center">
    <!-- 面包屑 S -->
    <div class="yh-h88 level_align yh-fz14">
      当前位置：
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item  to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item> <span class="yh-fc-blue-dan">{{type}}</span> </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 面包屑 E -->
    <!-- 富文本详情 S-->
    <div class="yh-pd30 yh-bbox" v-html="fwb">

    </div>
    <!-- 富文本详情 S-->
  </div>
</template>
<script>
export default {
  name: "about",
  data(){
    return {
      fwb:'',
     type:null,
    }
  },
  created(){
    this.type = this.$route.query.type
    this.getNoticeMessage()
  },
  methods: {
      //获取详情数据
      getNoticeMessage(){
        this.$axios.post('/api/index/newsDetail',{id:this.$route.query.id}).then(res=>{
          if (res.code === 1) {
            this.fwb = res.data.detail
          }
        })
      },
  }
}
</script>



<style scoped lang="scss">

</style>